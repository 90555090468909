<template>
  <div class="d-flex flex-column justify-content-center align-items-center col-12 mt-3">
    <div class="d-flex flex-row justify-content-around col-12">
      <button class="col-3 col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-2 overview-button">Overview</button>
      <input v-model="dateRangePicked" class="col-4 col-md-4 col-sm-5 col-xl-3" placeholder="Select Date..." type="text" id="datepicker" autocomplete="off"/>
      <button @click="confirmDateRangePicked(dateRangePicked)" class="col-3 col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-2 confirm-button">Confirm</button>
    </div>
    <div class="d-flex flex-column col-11">
      <div class="service-header mb-1">Please select a service:</div>
      <div class="col-3">
        <select @change="setProductSubscriptionData($event)" v-model="selectedServiceId" class="product-select-dropdown">
          <option value="null-All Services">All Services</option>
          <option value="6027-FitVids">Fit Vids (SID: 6027)</option>
          <option value="6342-GameSmash">Game Smash (SID: 6342)</option>
          <option value="6341-LottoWin">Lotto Win (SID: 6341)</option>
          <option value="9585-MdundoDjMixes">Mdundo Dj Mixes (SID: 9585)</option>
          <option value="6025-SocialStatus">Social Status (SID: 6025)</option>
          <option value="6416-UnlockYello">Unlock Yello (SID: 6416)</option>
          <option value="6028-CookIt">Cook It (SID: 6028)</option>
          <option value="6538-EpicFails">Epic Fails (SID: 6538)</option>
          <option value="9998-Game2Win">Game 2 Win (SID: 9998)</option>
          <option value="9133-LuckyMzanzi">Lucky Mzansi (SID: 9133)</option>
          <option value="10588-MzansiRhythm">Mzansi Rhythm (SID: 10588)</option>
          <option value="6867-SupaSaga">Supa Saga (SID: 6867)</option>
          <option value="10350-DFUDaily">DFU Daily (SID: 10350)</option>
          <option value="10351-DFUWeekly">DFU Weekly (SID: 10351)</option>
          <option value="10352-DFUMonthly">DFU Monthly (SID: 10352)</option>
        </select>
      </div>
    </div>
    <div class="d-flex flex-column align-items-center col-12 mt-2">
      <BarChart :subscriptionMetric="subscriptionMetric.dateDailyBreakdown"></BarChart>
    </div>
    <div v-if="serviceData" class="d-flex flex-column col-12 mt-4">
      <div class="d-flex flex-row justify-content-center">
        <div class="d-flex flex-column total-clicks-header metric-container col-3">
          <div class="game-name-text">{{ serviceData.Name }}</div>
          <div class="metric-container-header p-1 ">Total Clicks</div>
          <div class="metric-value-text m-1">{{ new Intl.NumberFormat('en-US').format(serviceData.TotalClicks) }}</div>
        </div>
        <div class="d-flex flex-column metric-container col-3">
          <div class="metric-container-header p-1">Unique Clicks</div>
          <div class="metric-value-text m-1">{{ new Intl.NumberFormat('en-US').format(serviceData.UniqueClicks) }}</div>
        </div>
        <div class="d-flex flex-column metric-container col-3">
          <div class="metric-container-header p-1">Unique Conversions</div>
          <div class="metric-value-text m-1">{{ new Intl.NumberFormat('en-US').format(serviceData.Conversions) }}</div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-column align-items-center mt-2 mb-2 col-12">
      <div class="col-11" style="overflow-x:auto;">
        <table class="table table-hover" id="tblToExcl">
          <thead>
          <tr>
            <th class="col-3" scope="col">Date</th>
            <th class="col-3" scope="col">Total Clicks</th>
            <th class="col-3" scope="col">Unique Clicks</th>
            <th class="col-3" scope="col">Conversions</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(day, index) in dailySubscriptionTable.slice().reverse()" :key=index>
            <td> {{ Helpers.dateConversion(day.Date.slice(0, 10)) }}</td>
            <td> {{ new Intl.NumberFormat('en-US').format(day.TotalClicks) }}</td>
            <td> {{ new Intl.NumberFormat('en-US').format(day.UniqueClicks) }}</td>
            <td> {{ new Intl.NumberFormat('en-US').format(day.Conversions) }}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <button class="d-flex flex-column justify-content-center export-button p-2 mb-3" @click="htmlTableToExcel()">Export to Excel</button>
    </div>
  </div>
</template>

<script>
import BarChart from "@/views/components/charts/BarChart";
import {mapMutations, mapState} from "vuex";
import moment from "moment";
import DashboardService from "@/services/dashboardService";
import flatpickr from "flatpickr";
import * as XLSX from 'xlsx/xlsx.mjs';

export default {
  name: "ProductSubscriptionUnlockMore",
  inheritAttrs: false,
  props: ['subscriptionMetric'],
  components: {
    BarChart
  },
  computed: {
    ...mapState(['productConfig', 'startingDateCalendar', 'endingDateCalendar'])
  },
  data() {
    return {
      dateRangePicked: `${moment().subtract(7, 'd').format('YYYY-MM-DD')} to ${moment().subtract(1, 'd').format('YYYY-MM-DD')}`,
      subscriptionMetricsData: [],
      startingDatePicked: undefined,
      endDatePicked: undefined,
      subscriptionServiceId: null,
      selectedServiceId: 'null-All Services',
      serviceName: undefined,
      serviceData: [],
      todayDate: `${moment().subtract(1, 'd').format('YYYY-MM-DD')}`,
      startingDate: `${moment().subtract(7, 'd').format('YYYY-MM-DD')}`,
      dailySubscriptionTable: [],
    }
  },
  methods: {
    ...mapMutations(['setTriggerNewChart', 'setBarChartData', 'setBarChartToLoad', 'setStartingDateCalendar', 'setEndingDateCalendar']),
    async confirmDateRangePicked(dateRange) {
      this.sliceDateRange(dateRange);
      await this.setSubscriptionMetrics()
      this.setTriggerNewChart(true)
      this.setBarChartData(this.subscriptionMetricsData.dateDailyBreakdown)
    },
    sliceDateRange(dateRange) {
      this.startingDatePicked = dateRange.slice(0, 10)
      this.endDatePicked = dateRange.substr(-10)
      this.setStartingDateCalendar(this.startingDatePicked)
      this.setEndingDateCalendar(this.endDatePicked)
    },
    async setSubscriptionMetrics() {
      let getUnlockMoreSubscriptionStatsResponse = await DashboardService.getUnlockMoreSubscriptionStats(`${this.startingDatePicked}`, `${this.endDatePicked}`, this.subscriptionServiceId)
      this.updatedSubsctriptionMetricsData = getUnlockMoreSubscriptionStatsResponse.data;
      this.subscriptionMetricsData = this.updatedSubsctriptionMetricsData;
      this.dailySubscriptionTable = this.subscriptionMetricsData.dateDailyBreakdown;
      this.destructureSelectedServiceId();
    },
    setUpdatedDateRange() {
      this.dateRangePicked = `${this.startingDateCalendar} to ${this.endingDateCalendar}`
      this.startingDatePicked = this.startingDateCalendar
      this.endDatePicked = this.endingDateCalendar
    },
    triggerUpdatedChart() {
      this.setTriggerNewChart(true)
      this.setBarChartData(this.subscriptionMetricsData.dateDailyBreakdown)
      this.setBarChartToLoad(this.productConfig.bannerClicksGraph)
    },
    async checkDateRangeChanged() {
      if (this.startingDateCalendar !== undefined) {
        this.setUpdatedDateRange()
        await this.setSubscriptionMetrics()
        this.triggerUpdatedChart()
      } else {
        this.setDefaultSubscriptionMetrics()
        this.setBarChartToLoad(this.productConfig.bannerClicksGraph)
      }
    },
    setDefaultSubscriptionMetrics() {
      this.subscriptionMetricsData = this.subscriptionMetric;
      this.dailySubscriptionTable = this.subscriptionMetricsData.dateDailyBreakdown
    },
    destructureSelectedServiceId() {
      this.serviceData = this.subscriptionMetricsData.totalCumulativeSection[0]
    },
    async setProductSubscriptionData(event) {
      this.selectedServiceId = event.target.value;
      this.subscriptionServiceId = Number(this.selectedServiceId.split('-')[0]);
      this.startingDatePicked = this.dateRangePicked.slice(0, 10);
      this.endDatePicked = this.dateRangePicked.substr(-10);
      await this.setSubscriptionMetrics();
      this.setTriggerNewChart(true);
      this.setBarChartData(this.subscriptionMetricsData.dateDailyBreakdown);
    },
    htmlTableToExcel() {
      let table_elt = document.getElementById("tblToExcl");
      let workbook = XLSX.utils.table_to_book(table_elt);
      XLSX.writeFile(workbook, `${this.productConfig.productTitle} ${this.selectedServiceId} Subscription Report.xlsb`);
    },
  },
  mounted() {
    flatpickr('#datepicker', {
      mode: "range"
    })
  },
  async beforeMount() {
    await this.checkDateRangeChanged()
    this.destructureSelectedServiceId();
  },
}
</script>

<style scoped>
.overview-button, .confirm-button, .export-button {
  height: 35px;
  border-radius: 18px;
  font-weight: 600;
  font-size: 20px;
  border: 0;
  background-color: #EE5C36;
  color: #FFFFFF;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

input {
  text-align: center;
}

.metric-container {
  border-radius: 12px;
  font-weight: 600;
  border: 0;
  background-color: #F4D1CE;
  color: #000000;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  width: 250px;
  margin: 20px;
}

.metric-container-header {
  font-size: 18px;
}

.metric-value-text {
  font-size: 30px;
  padding-left: 3px;
}

.total-clicks-header {
  position: relative;
  font-size: 25px;
}

.game-name-text {
  left: 5px;
  top: -40px;
  position: absolute;
}

.service-header {
  font-size: 20px;
  font-weight: 700;
  margin-top: 10px;
}

.product-select-dropdown {
  height: 32px;
}

@media only screen and (max-width: 576px) {
  .overview-button, .confirm-button {
    height: 28px;
    font-size: 18px;
  }

  input {
    font-size: 10.5px;
  }

  .metric-container-header {
    font-size: 13px;
  }

  .metric-value-text {
    font-size: 16px;
  }

  .metric-container {
    width: 25%;
    margin-bottom: -5px;
  }

  .total-clicks-header {
    font-size: 20px;
  }

  .game-name-text {
    left: 5px;
    top: -30px;
    position: absolute;
    font-size: 17px;
    width: 150px;
  }

  table {
    margin-top: 20px;
    font-size: 10.5px;
  }

  .export-button {
    height: 28px;
    font-size: 15px;
  }

}
</style>
