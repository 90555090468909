<template>
  <SaveChanges @cancel-clicked="onCancelClicked()"  v-if="showConfirmEdit" :isChannel="true"/>
  <div class="d-flex flex-column px-5 mt-3">
    <div class="d-flex justify-content-between w-100">
      <div class="d-flex justify-content-start align-items-center">
        <div @click="onViewCampaignClicked()" class="main-btn d-flex justify-content-center align-items-center"
             v-bind:class="getSelectedBoxColour('View')">View Channels
        </div>
        <div @click="onAddCampaignClicked()" class="main-btn d-flex justify-content-center align-items-center mx-3"
             v-bind:class="getSelectedBoxColour('Add')"><img class="add-icon mx-1"
                                                             src="../../../../assets/products/add-icon.png" alt="">Add
          Channel
        </div>
      </div>
      <div v-if="showSaveChanges" class="d-flex justify-content-start align-items-center">
        <div @click="onSaveChagesClicked()" class="main-btn d-flex justify-content-center align-items-center green">Save changes
        </div>
        <div @click="onCancelClicked()" class="main-btn d-flex justify-content-center align-items-center mx-3">Cancel
        </div>
      </div>
    </div>
    <div v-if="showViewChannels" class="d-flex mx-2 flex-column w-100 mt-4 border-bottom">
      <div class="d-flex justify-content-evenly align-items-center">
        <div class="d-flex flex-column justify-content-center align-items-center box-width" v-for="(dropDown, index) in dropsDowns">
          <DropDownBox :isChannel="true" :dropDownOptions="getDropDownOptions(dropDown)" :dropDownName="getDropDownName(dropDown)" :index=index />
        </div>
      </div>
    </div>
    <div v-if="showViewChannels" class="d-flex mt-2 mb-2 count-txt">
      <i>Channel Count: <span><b>{{ this.campaignChannelTable.length }}</b></span></i>
    </div>
    <div v-if="showViewChannels" @click="onChannelClicked(channel)" class="d-flex justify-content-evenly mt-3 w-100 box-height" v-for="channel in this.campaignChannelTable" v-bind:class="getSelectedCampaignClass(channel)">
      <span class="col-2 d-flex justify-content-center align-items-center box-width">{{ channel.ChannelId }}</span>
      <div class="box-width d-flex justify-content-center align-items-center">
        <!--        <input v-model="selectedCampaign.CampaignName" v-if="campaign.isSelected" type="text" :placeholder="campaign.CampaignName" class="d-flex justify-content-center align-items-center">-->
        <span class="d-flex justify-content-center align-items-center">{{ channel.ChannelName }}</span>
      </div>
      <div class="box-width d-flex justify-content-center align-items-center">
        <!--        <input v-model="selectedCampaign.ChannelName" v-if="campaign.isSelected" type="text" :placeholder="campaign.ChannelName" class="d-flex justify-content-center align-items-center">-->
        <span class="d-flex justify-content-center align-items-center">{{ channel.SubscriptionPostbackUrl }}</span>
      </div>
      <div class="box-width d-flex justify-content-center align-items-center">
        <!--        <input v-model="selectedCampaign.Country" v-if="campaign.isSelected" type="text" :placeholder="campaign.Country" class="d-flex justify-content-center align-items-center">-->
        <span class="d-flex justify-content-center align-items-center">{{ channel.BillingPostbackUrl }}</span>
      </div>
      <div class="box-width d-flex justify-content-center align-items-center">
        <input @change="onInputChanged(channel)" v-model="selectedChannels.CostCalculationType" v-if="channel.isSelected" type="text" :placeholder="channel.CostCalculationType" class="d-flex justify-content-center align-items-center">
        <span v-if="!channel.isSelected" class="d-flex justify-content-center align-items-center">{{ channel.CostCalculationType }}</span>
      </div>
      <div class="box-width d-flex justify-content-center align-items-center">
        <!--        <input v-model="selectedCampaign.NominalCpa" v-if="campaign.isSelected" type="text" :placeholder="campaign.NominalCpa" class="d-flex justify-content-center align-items-center">-->
        <span class="d-flex justify-content-center align-items-center">{{ channel.Currency }}</span>
      </div>
      <div class="box-width d-flex justify-content-center align-items-center">
        <!--        <input v-model="selectedCampaign.Network" v-if="campaign.isSelected" type="text" :placeholder="campaign.Network" class="d-flex justify-content-center align-items-center">-->
        <span class="d-flex justify-content-center align-items-center">{{ channel.ClickId }}</span>
      </div>
      <div class="box-width d-flex justify-content-center align-items-center">
        <!--        <input v-model="selectedCampaign.Network" v-if="campaign.isSelected" type="text" :placeholder="campaign.Network" class="d-flex justify-content-center align-items-center">-->
        <span class="d-flex justify-content-center align-items-center">{{ channel.Publisher }}</span>
      </div>
    </div>
  </div>
  <div v-if="showAddChannel" class="d-flex mt-5 left-margin">
    <div class="d-flex flex-column">
      <div class="d-flex flex-column m-3 field-box">
        <h5>Channel Name</h5>
        <input v-model="channelName" class="d-flex justify-content-center align-items-center h-100 p-2" type="text" placeholder="Channel Name"/>
      </div>
      <div class="d-flex flex-column m-3 field-box">
        <h5>Subscription Postback</h5>
        <input v-model="subscriptionPostBackUrl" class="d-flex justify-content-center align-items-center h-100 p-2" type="text" placeholder="Enter Postback URL"/>
      </div>
      <div class="d-flex flex-column m-3 field-box">
        <h5>Billing Postback URl (Optional)</h5>
        <input v-model="billingpostBackUrl" class="d-flex justify-content-center align-items-center h-100 p-2" type="text" placeholder="Enter Postback URL"/>
      </div>
      <div class="d-flex flex-column m-3 field-box">
        <h5>Publisher (Optional)</h5>
        <input v-model="publisher" class="d-flex justify-content-center align-items-center h-100 p-2" type="text" placeholder="Enter Publisher"/>
      </div>
    </div>

    <div class="d-flex flex-column mx-5">
      <div class="d-flex flex-column m-3  field-box">
        <h5 class="p-2">Cost Calculation Type</h5>
        <div @click="toggleDropdown('costCalculationtype')" class="dropdown-box d-flex justify-content-between align-items-center">
          <div class="d-flex justify-content-center align-items-center p-2">{{costCalculationtype.name}}</div>
          <span :class="{'arrow-down': !isCostCalculationsTypeDownOpen, 'arrow-up': isCostCalculationsTypeDownOpen}"></span>
        </div>
        <AddCampaignDropDown @update-dropdown="toggleDropdown('costCalculationtype')" :dropDownOptions="costCalculationTypeOptions" v-if="isCostCalculationsTypeDownOpen" header="CostCalculation"/>
      </div>
      <div class="d-flex flex-column m-3  field-box">
        <h5 class="p-2">Currency</h5>
        <div @click="toggleDropdown('currency')" class="dropdown-box d-flex justify-content-between align-items-center">
          <div class="d-flex justify-content-center align-items-center p-2">{{currency.name}}</div>
          <span :class="{'arrow-down': !isCurrencyOpen, 'arrow-up': isCurrencyOpen}"></span>
        </div>
        <AddCampaignDropDown @update-dropdown="toggleDropdown('currency')"  :dropDownOptions="currencyOptions" v-if="isCurrencyOpen" header="Currency"/>
      </div>
      <div class="d-flex flex-column m-3 field-box">
        <h5>Clicked ID Parameter Name</h5>
        <input v-model="clickIdParameter" class="d-flex justify-content-center align-items-center h-100 p-2" type="text" placeholder="Enter Click ID Parameter Name"/>
      </div>
    </div>
    <div class="d-flex bottom-buttons-position">
      <div @click="onCreateChannelClicked()" v-if="!isFormNotCompeted()" class="main-button orange d-flex justify-content-center align-items-center">Create Channel</div>
      <div v-if="isFormNotCompeted()" class="main-button orange-black d-flex justify-content-center align-items-center">Create Channel</div>
      <div @click="onCancelCampaignClicked()" class="main-button d-flex justify-content-center align-items-center">Cancel</div>
    </div>
  </div>
  <div v-if="campaignChannelTable.length === 0 && !showAddChannel" class="d-flex flex-column align-items-center col-11">
    <img class="col-2 mt-3" src="../../../../assets/no-campaign-found-icon.png">
    <div class="text-center mt-2 col-5">We couldn't find any channels that match you criteria. Please try creating
      a new channel or adjusting your search parameters.
    </div>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import CampaignHubService from "@/services/campaignHubService";
import DropDownBox from "@/views/components/marketing/DropDownBox";
import AddCampaignDropDown from "@/views/components/marketing/AddCampaignDropDown";
import SaveChanges from "@/views/components/marketing/SaveChanges";

export default {
  name: "ChannelsView",
  components: {
    DropDownBox,
    AddCampaignDropDown,
    SaveChanges
  },
  data() {
    return {
      clickIdParameter: '',
      billingpostBackUrl: '',
      subscriptionPostBackUrl: '',
      nominalCpa: 0,
      postBackPercentage: 0,
      selectedMainButton: 'View',
      dropsDowns: [],
      selectedChannels: [],
      showSaveChanges: false,
      showViewChannels: true,
      showAddChannel: false,
      channelName: '',
      publisher: '',
      costCalculationTypeOptions: [],
      isCostCalculationsTypeDownOpen: false,
      currencyOptions: [],
      isCurrencyOpen: false
    }
  },
  computed: {
    ...mapState(['isLoading', 'productConfig', 'userData', 'campaignChannelTable', 'costCalculationtype', 'currency', 'showConfirmEdit', 'editedCampaigns'])
  },
  methods: {
    ...mapMutations(['setIsLoading', 'setCampaignChannelTable', 'setCostCalculationtype', 'setCurrency', 'pushEditedCampaign', 'setShowConfirmEdit']),
    onInputChanged(channel) {
      this.pushEditedCampaign(channel)
    },
    async onCreateChannelClicked() {
      const billingUrl = this.billingpostBackUrl === 'Enter Postback URL' ? null : this.billingpostBackUrl
      await CampaignHubService.createChannel(this.channelName, this.subscriptionPostBackUrl, billingUrl, this.costCalculationtype.parameter, this.currency.parameter, this.clickIdParameter, this.userData.LoginName, this.publisher)
      const response = await this.setChannels()
      if (response.result) {
        this.showAddChannel = false;
        this.showViewChannels = true;
      }
      await this.getChannelDropDowns();
    },
    onCancelCampaignClicked() {
      this.clickIdParameter =  ''
      this.billingpostBackUrl = ''
      this.subscriptionPostBackUrl =  ''
      this.channelName = ''
      this.setCostCalculationtype({name: 'Select Cost Type'})
      this.setCurrency({name: 'Select Currency'})
    },
    isFormNotCompeted() {
      if (this.costCalculationtype === {name: 'Select Cost Type'}) return true
      if (this.currency === {name: 'Select Currency'}) return true
      if (this.clickIdParameter.name === 'Enter Click ID Parameter Name') return true
      if (!this.subscriptionPostBackUrl) return true
      if (!this.clickIdParameter) return true
      if (this.costCalculationtype.name === 'Select Cost Type') return true
      if (this.currency.name === 'Select Currency') return true
    },
    toggleDropdown(dropDown) {
      if (dropDown === 'costCalculationtype')  this.isCostCalculationsTypeDownOpen = !this.isCostCalculationsTypeDownOpen;
      if (dropDown === 'currency')  this.isCurrencyOpen = !this.isCurrencyOpen;
    },
    onViewCampaignClicked() {
      this.showAddChannel = false
      this.showViewChannels = true
      this.selectedMainButton = 'View'
    },
    async onAddCampaignClicked() {
      this.showViewChannels = false
      this.showAddChannel = true
      this.selectedMainButton = 'Add'
      await this.setAddCampaignDropDowns();
    },

    clearDropDownOptions() {
      this.costCalculationTypeOptions = []
      this.currencyOptions = []
    },

    async setAddCampaignDropDowns() {
      this.clearDropDownOptions();
      const dropsDowns = await CampaignHubService.getChannelDropDowns(true);
      if (dropsDowns.length < 1) return
      dropsDowns.forEach((dropDown) => {
        dropDown.forEach((option) => {
          if (option.Header === 'Cost Calculation Type') this.costCalculationTypeOptions.push({name: option.DisplayName, parameter: option.Parameter})
          if (option.Header === 'Currency') this.currencyOptions.push({name: option.DisplayName, parameter: option.Parameter})
        })
      })
    },

    async onSaveChagesClicked() {
      this.setShowConfirmEdit(true);
      this.showSaveChanges = false;
    },
    onCancelClicked() {
      this.campaignChannelTable.forEach((campaign) => campaign.isSelected = false)
      this.showSaveChanges = false
    },
    getSelectedCampaignClass(campaign) {
      if (campaign.isSelected) return 'selected'
    },
    onChannelClicked(channel) {

    },
    getDropDownName(dropDown) {
      if (dropDown.length > 0) return this.getFirstKey(dropDown[0])
    },
    getDropDownOptions(dropDown) {
      return dropDown.map(item => {
        return {
          displayName: item.DisplayName.toString(),
          parameter: item.Parameter
        } // Convert its value to a string
      });
    },
    getFirstKey(obj) {
      return obj.Header
    },
    getSelectedBoxColour(btn) {
      if (btn === this.selectedMainButton) return 'orange'
    },
    async setChannels() {
      const campaigns = await CampaignHubService.getChannels()
      this.setCampaignChannelTable(campaigns)
      return {result: true}
    },
    async getChannelDropDowns() {
      const dropDowns = await CampaignHubService.getChannelDropDowns(false);
      dropDowns.forEach((dropDown) => dropDown.selectedField = 'All')
      this.dropsDowns = dropDowns
    },
  },
  async beforeMount() {
    await this.getChannelDropDowns();
    await this.setChannels();
  }
}
</script>

<style scoped>
.main-button {
  width: 200px;
  height: 50px;
  border: solid gray 1px;
  margin-right: 20px;
  margin-left: 20px;
}
.bottom-buttons-position {
  position: absolute;
  bottom: 100px;
  left: 50px
}
.arrow-down,
.arrow-up {
  display: inline-block;
  margin-left: 8px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  margin-right: 5px;
}

.arrow-down {
  border-top: 6px solid #EE5C36; /* Downward arrow */
}

.arrow-up {
  border-bottom: 6px solid #EE5C36; /* Upward arrow */
}

.left-margin {
  margin-left: 40px;
}
.field-box {
  width: 450px;
  height: 75px;
}
.dropdown-box {
  height: 100%;
  width: 100%;
  border: solid 1px gray;
}
.box-height {
  height: 50px;
}
.box-width {
  width: 14.2857142857%;
}
.edit-icon {
  width: 15px;
  height: auto;
  margin-right: 10px;
}

.count-txt {
  font-size: 18px !important;
}

.main-btn {
  width: 150px;
  height: 50px;
  border: solid 1px black;
}

.orange {
  background-color: #EE5C36;
  color: white !important;
}

.orange-black {
  background-color: #A63E2D; /* Brownish-red background */
  color: rgba(255, 255, 255, 0.7); /* Semi-transparent white text */
}

.green {
  background-color: green;
  color: white !important;
}

.selected {
  background-color: #EE5C36;
}

.add-icon {
  height: 50%;
  width: auto;
}

.down-arrow {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  height: 70%;
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  margin-left: 10px;
}

.selected-field-name-box {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /*border-top: solid grey 1px;*/
  /*border-bottom: solid grey 1px;*/
}

.line {
  position: absolute;
  height: 1px;
  width: 95vw;
  background-color: #7e7a7a;

}

.top {
  top: 0;
  left: 0;
}

.bottom {
  bottom: 0;
  left: 0;
}
</style>

