<template>
  <div class="d-flex flex-column align-items-center sidebar-container">
    <img class="mt-2 col-8 penrose-logo" src="../../assets/penrose-logo.png">
    <div class="d-flex flex-row justify-content-evenly mt-2 col-10">
      <button @click="this.setSidebarMenuVisible(false)" class="col-2 close-button">X</button>
      <button @click="logoutButtonClicked()" class="col-7 logout-button">Log Out</button>
    </div>
    <div class="mt-2 col-10">
      <div v-if="userData.Vodacom || userData.HyveMobile || userData.Masterstream" @click="toggleDropdown(0)" class="d-flex flex-row cell-provider-container align-items-center col-12">
        <div class="col-10 cell-provider-name-text">Vodacom</div>
        <div class="col-1 dropdown-box text-center">&#x25BC</div>
      </div>
      <Transition name="slide">
        <div v-if="isDropdownOpen(0)" class="d-flex flex-row align-items-center drop-down-menu-vc col-12 mt-3">
          <div class="d-flex flex-column col-12 product-container">
            <div v-if="userData.Vodacom || userData.HyveMobile" @click="networkProductClicked('quiz-inc')"
                 :class="`d-flex flex-row ${productConfig.productId === 5 ? 'game-container-selected' : 'game-container-default'}`">
              <img class="product-logo" src="../../assets/products/quiz-inc-logo.png">
              <div class="col-10 product-text">QuizInc</div>
            </div>
            <div v-if="userData.Vodacom || userData.HyveMobile" @click="networkProductClicked('quiz-inc-aap')"
                 :class="`d-flex flex-row ${productConfig.productId === 24 ? 'game-container-selected mt-2' : 'game-container-default mt-2'}`">
              <img class="product-logo" src="../../assets/products/quiz-inc-aap-logo.png">
              <div class="col-10 product-text">QuizInc All Access Pass</div>
            </div>
            <div v-if="userData.Vodacom || userData.HyveMobile" @click="networkProductClicked('quiz-inc-head-2-head')"
                 :class="`d-flex flex-row ${productConfig.productId === 21  ? 'game-container-selected mt-2' : 'game-container-default mt-2'}`">
              <img class="product-logo" src="../../assets/products/quiz-inc-head-2-head-logo.png">
              <div class="col-10 product-text">QuizInc Head-2-Head</div>
            </div>
            <div v-if="userData.Vodacom || userData.HyveMobile" @click="networkProductClicked('stop-the-clock')"
                 :class="`d-flex flex-row ${productConfig.productId === 3 ? 'game-container-selected mt-2' : 'game-container-default mt-2'}`">
              <img class="product-logo" src="../../assets/products/stop-the-clock-logo.png">
              <div class="col-10 product-text">Stop The Clock</div>
            </div>
            <div v-if="userData.Vodacom || userData.HyveMobile" @click="networkProductClicked('unlock-three')"
                 :class="`d-flex flex-row ${productConfig.productId === 6  ? 'game-container-selected mt-2' : 'game-container-default mt-2'}`">
              <img class="product-logo" src="../../assets/products/unlock-three-logo.png">
              <div class="col-10 product-text">Unlock 3</div>
            </div>
            <div v-if="userData.Masterstream" @click="networkProductClicked('master-stream')"
                 :class="`d-flex flex-row ${productConfig.productId === 29 ? 'game-container-selected mt-2' : 'game-container-default mt-2'}`">
              <img class="product-logo" src="../../assets/products/master-stream-logo.png">
              <div class="col-10 product-text">MasterStream</div>
            </div>
            <div v-if="userData.Vodacom" @click="networkProductClicked('beat-the-jams')"
                 :class="`d-flex flex-row ${productConfig.productId === 18  ? 'game-container-selected mt-2' : 'game-container-default mt-2'}`">
              <img class="product-logo" src="../../assets/products/beat-the-jams-logo.png">
              <div class="col-10 product-text">Beat The Jams</div>
            </div>
            <div v-if="userData.Vodacom" @click="networkProductClicked('vodacom-fantasy')"
                 :class="`d-flex flex-row ${productConfig.productId === 8 ? 'game-container-selected mt-2' : 'game-container-default mt-2'}`">
              <img class="product-logo" src="../../assets/products/vodacom-fantasy-logo.png">
              <div class="col-10 product-text">Vodacom Fantasy</div>
            </div>
            <div v-if="userData.Vodacom" @click="networkProductClicked('freeplay-predictor')"
                 :class="`d-flex flex-row ${productConfig.productId === 55 ? 'game-container-selected mt-2' : 'game-container-default mt-2'}`">
              <img class="product-logo" src="../../assets/products/freeplay-predictor-logo.png">
              <div class="col-10 product-text">Freeplay Pick'em</div>
            </div>
            <div v-if="userData.Vodacom" @click="networkProductClicked('mini-app')"
                 :class="`d-flex flex-row ${productConfig.productId === 2 ? 'game-container-selected mt-2' : 'game-container-default mt-2'}`">
              <img class="product-logo" src="../../assets/products/mini-app-logo.png">
              <div class="col-10 product-text">MiniApp</div>
            </div>
            <div v-if="userData.Internal" @click="networkProductClicked('vc-mzansi-games')"
                 :class="`d-flex flex-row ${productConfig.productId === 23 ? 'game-container-selected mt-2' : 'game-container-default mt-2'}`">
              <img class="product-logo" src="../../assets/products/vc-mzansi-games-logo.png">
              <div class="col-10 product-text">Mzansi Games</div>
            </div>
            <div v-if="userData.Vodacom" @click="networkProductClicked('cricket-zone')"
                 :class="`d-flex flex-row ${productConfig.productId === 26 ? 'game-container-selected mt-2' : 'game-container-default mt-2'}`">
              <img class="product-logo" src="../../assets/products/cricket-zone-logo.png">
              <div class="col-10 product-text">Cricket Zone</div>
            </div>
            <div v-if="userData.Vodacom" @click="networkProductClicked('motorsport-zone')"
                 :class="`d-flex flex-row ${productConfig.productId === 27 ? 'game-container-selected mt-2' : 'game-container-default mt-2'}`">
              <img class="product-logo" src="../../assets/products/motorsport-zone-logo.png">
              <div class="col-10 product-text">Motorsport Zone</div>
            </div>
            <div v-if="userData.Vodacom" @click="networkProductClicked('rugby-zone')"
                 :class="`d-flex flex-row ${productConfig.productId === 28 ? 'game-container-selected mt-2' : 'game-container-default mt-2'}`">
              <img class="product-logo" src="../../assets/products/rugby-zone-logo.png">
              <div class="col-10 product-text">Rugby Zone</div>
            </div>
            <div v-if="userData.Vodacom" @click="networkProductClicked('chess-masters')"
                 :class="`d-flex flex-row ${productConfig.productId === 36 ? 'game-container-selected mt-2' : 'game-container-default mt-2'}`">
              <img class="product-logo" src="../../assets/products/chess-masters-logo.png">
              <div class="col-10 product-text">Chess Masters</div>
            </div>
            <div v-if="userData.Openly" @click="networkProductClicked('openly-space')"
                 :class="`d-flex flex-row ${productConfig.productId === 38 ? 'game-container-selected mt-2' : 'game-container-default mt-2'}`">
              <img class="product-logo" src="../../assets/products/openly-space-logo.png">
              <div class="col-10 product-text">Openly</div>
            </div>
          </div>
        </div>
      </Transition>
      <div v-if="userData.MTN || userData.Digitata || userData.HyveMobile" @click="toggleDropdown(1)" class="d-flex flex-row cell-provider-container align-items-center col-12 mt-2">
        <div class="col-10 cell-provider-name-text">MTN</div>
        <div class="col-1 dropdown-box text-center">&#x25BC</div>
      </div>
      <Transition name="slide">
        <div v-if="isDropdownOpen(1)" class="d-flex flex-row align-items-center drop-down-menu col-12 mt-3">
          <div class="d-flex flex-column col-12 product-container">
            <div v-if="userData.MTN" @click="networkProductClicked('mzansi-rhythm')"
                 :class="`d-flex flex-row ${productConfig.productId === 35 ? 'game-container-selected' : 'game-container-default'}`">
              <img class="product-logo" src="../../assets/products/mzansi-rhythm-logo.png">
              <div class="col-10 product-text">Mzansi Rhythm</div>
            </div>
            <div v-if="userData.MTN" @click="networkProductClicked('mzansi-games')"
                 :class="`d-flex flex-row ${productConfig.productId === 4 ? 'game-container-selected mt-2' : 'game-container-default mt-2'}`">
              <img class="product-logo" src="../../assets/products/mzansi-games-logo.png">
              <div class="col-10 product-text">Y’ello Rush</div>
            </div>
            <div v-if="userData.MTN || userData.Digitata" @click="networkProductClicked('game-to-win')"
                 :class="`d-flex flex-row ${productConfig.productId === 1 ? 'game-container-selected mt-2' : 'game-container-default mt-2'}`">
              <img class="product-logo" src="../../assets/products/game-to-win-logo.png">
              <div class="col-10 product-text">*Game2Win#</div>
            </div>
            <div v-if="userData.MTN" @click="networkProductClicked('unlock-more')"
                 :class="`d-flex flex-row ${productConfig.productId === 0 ? 'game-container-selected mt-2' : 'game-container-default mt-2'}`">
              <img class="product-logo" src="../../assets/products/unlock-more-logo.png">
              <div class="col-10 product-text">Unlock More</div>
            </div>
            <div v-if="userData.MTN || userData.HyveMobile" @click="networkProductClicked('unlock-yello')"
                 :class="`d-flex flex-row ${productConfig.productId === 37 ? 'game-container-selected mt-2' : 'game-container-default mt-2'}`">
              <img class="product-logo" src="../../assets/products/unlock-yello-logo.png">
              <div class="col-10 product-text">Unlock Y'ello</div>
            </div>
          </div>
        </div>
      </Transition>
      <div v-if="userData.Upstream" @click="toggleDropdown(2)" class="d-flex flex-row cell-provider-container align-items-center col-12 mt-2">
        <div class="col-10 cell-provider-name-text">Upstream Fantasy</div>
        <div class="col-1 dropdown-box text-center">&#x25BC</div>
      </div>
      <Transition name="slide">
        <div v-if="isDropdownOpen(2) && userData.Upstream" class="d-flex flex-row align-items-center drop-down-menu col-12 mt-3">
          <div class="d-flex flex-column col-12 product-container">
            <div @click="networkProductClicked('mtn-cdl-fantasy')"
                 :class="`d-flex flex-row ${productConfig.productId === 13 ? 'game-container-selected' : 'game-container-default'}`">
              <img class="product-logo" src="../../assets/products/mtn-cdl-fantasy-logo.png">
              <div class="col-10 product-text">Ivory Coast Fantasy</div>
            </div>
            <div @click="networkProductClicked('mtn-cmr-fantasy')"
                 :class="`d-flex flex-row ${productConfig.productId === 14 ? 'game-container-selected mt-2' : 'game-container-default mt-2'}`">
              <img class="product-logo" src="../../assets/products/mtn-cmr-fantasy-logo.png">
              <div class="col-10 product-text">Cameroon Fantasy</div>
            </div>
            <div @click="networkProductClicked('mtn-nga-fantasy')"
                 :class="`d-flex flex-row ${productConfig.productId === 15 ? 'game-container-selected mt-2' : 'game-container-default mt-2'}`">
              <img class="product-logo" src="../../assets/products/mtn-nga-fantasy-logo.png">
              <div class="col-10 product-text">Nigeria Fantasy</div>
            </div>
            <div @click="networkProductClicked('mtn-zmb-fantasy')"
                 :class="`d-flex flex-row ${productConfig.productId === 17 ? 'game-container-selected mt-2' : 'game-container-default mt-2'}`">
              <img class="product-logo" src="../../assets/products/mtn-zmb-fantasy-logo.png">
              <div class="col-10 product-text">Zambia Fantasy</div>
            </div>
            <div @click="networkProductClicked('eth-et-fantasy')"
                 :class="`d-flex flex-row ${productConfig.productId === 16 ? 'game-container-selected mt-2' : 'game-container-default mt-2'}`">
              <img class="product-logo" src="../../assets/products/eth-et-fantasy-logo.png">
              <div class="col-10 product-text">Ethiotel Fantasy</div>
            </div>
            <div @click="networkProductClicked('airtel-nga-fantasy')"
                 :class="`d-flex flex-row ${productConfig.productId === 22 ? 'game-container-selected mt-2' : 'game-container-default mt-2'}`">
              <img class="product-logo" src="../../assets/products/airtel-nga-fantasy-logo.png">
              <div class="col-10 product-text">Airttel Fantasy</div>
            </div>
          </div>
        </div>
      </Transition>
      <div v-if="userData.Internal" @click="toggleDropdown(3)" class="d-flex flex-row cell-provider-container align-items-center col-12 mt-2">
        <div class="col-10 cell-provider-name-text">Penrose Internal</div>
        <div class="col-1 dropdown-box text-center">&#x25BC</div>
      </div>
      <Transition name="slide">
        <div v-if="isDropdownOpen(3) && userData.Internal" class="d-flex flex-row align-items-center drop-down-menu col-12 mt-3">
          <div class="d-flex flex-column col-12 product-container">
            <div @click="networkProductClicked('penrose-awards')"
                 :class="`d-flex flex-row ${productConfig.productId === 999 ? 'game-container-selected' : 'game-container-default'}`">
              <img class="product-logo" src="../../assets/products/penrose-awards-logo.png">
              <div class="col-10 product-text">Awarding Spend</div>
            </div>
            <div @click="networkProductClicked('campaign-ids')"
                 :class="`d-flex flex-row ${productConfig.productId === 666 ? 'game-container-selected mt-2' : 'game-container-default mt-2'}`">
              <img class="product-logo" src="../../assets/products/campaign-ids-logo.png">
              <div class="col-10 product-text">Campaign IDs</div>
            </div>
          </div>
        </div>
      </Transition>
      <div v-if="userData.Derisk" @click="toggleDropdown(4)" class="d-flex flex-row cell-provider-container align-items-center col-12 mt-2">
        <div class="col-10 cell-provider-name-text">Derisk</div>
        <div class="col-1 dropdown-box text-center">&#x25BC</div>
      </div>
      <Transition name="slide">
        <div v-if="isDropdownOpen(4) && userData.Derisk" class="d-flex flex-row align-items-center drop-down-menu col-12 mt-3">
          <div class="d-flex flex-column col-12 product-container">
            <div @click="networkProductClicked('voucher-awards')"
                 :class="`d-flex flex-row ${productConfig.productId === 1000 ? 'game-container-selected' : 'game-container-default'}`">
              <img class="product-logo" src="../../assets/products/voucher-awards-logo.png">
              <div class="col-10 product-text">Voucher Awards</div>
            </div>
          </div>
        </div>
      </Transition>
      <div v-if="userData.Internal" @click="toggleDropdown(5)" class="d-flex flex-row cell-provider-container align-items-center col-12 mt-2">
        <div class="col-10 cell-provider-name-text">AIS</div>
        <div class="col-1 dropdown-box text-center">&#x25BC</div>
      </div>
      <Transition name="slide">
        <div v-if="isDropdownOpen(5) && userData.Internal" class="d-flex flex-row align-items-center drop-down-menu col-12 mt-3">
          <div class="d-flex flex-column col-12 product-container">
            <div @click="networkProductClicked('ais-fantasy')"
                 :class="`d-flex flex-row ${productConfig.productId === 34 ? 'game-container-selected' : 'game-container-default'}`">
              <img class="product-logo" src="../../assets/products/ais-fantasy-logo.png">
              <div class="col-10 product-text">Fantasy Football</div>
            </div>
            <div @click="networkProductClicked('ais-mmr')"
                 :class="`d-flex flex-row ${productConfig.productId === 33 ? 'game-container-selected mt-2' : 'game-container-default mt-2'}`">
              <img class="product-logo" src="../../assets/products/ais-mmr-logo.png">
              <div class="col-10 product-text">Megamatch Rivals</div>
            </div>
          </div>
        </div>
      </Transition>
      <div v-if="userData.Internal" @click="toggleDropdown(6)" class="d-flex flex-row cell-provider-container align-items-center col-12 mt-2">
        <div class="col-10 cell-provider-name-text">Marketing</div>
        <div class="col-1 dropdown-box text-center">&#x25BC</div>
      </div>
      <Transition name="slide">
        <div v-if="isDropdownOpen(6) && userData.Internal" class="d-flex flex-row align-items-center drop-down-menu col-12 mt-3">
          <div class="d-flex flex-column col-12 product-container">
            <div @click="networkProductClicked('channels')"
                 :class="`d-flex flex-row ${productConfig.productId === 1999 ? 'game-container-selected' : 'game-container-default'}`">
              <img class="product-logo" src="../../assets/products/channels-logo.png">
              <div class="col-10 product-text">Channels</div>
            </div>
            <div @click="networkProductClicked('campaigns')"
                 :class="`d-flex flex-row ${productConfig.productId === 2000 ? 'game-container-selected mt-2' : 'game-container-default mt-2'}`">
              <img class="product-logo" src="../../assets/products/campaigns-logo.png">
              <div class="col-10 product-text">Campaigns</div>
            </div>
          </div>
        </div>
      </Transition>
    </div>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";

export default {
  name: "SidebarMenu",
  computed: {
    ...mapState(['productConfig', 'userData'])
  },
  data() {
    return {
      dropDownVisible: false,
      openDropdown: null,
      selectedDropDown: null,
    }
  },
  methods: {
    ...mapMutations(['setUserData', 'setIsLoading', 'setSidebarMenuVisible']),
    toggleDropdown(dropdown) {
      if (this.openDropdown === dropdown) {
        this.openDropdown = null;
      } else {
        this.openDropdown = dropdown;
        this.selectedDropDown = this.openDropdown;
      }
    },
    isDropdownOpen(dropdown) {
      return this.openDropdown === dropdown;
    },
    networkProductClicked(productEndpoint) {
      this.$router.push(`/dashboard/${productEndpoint}`);
      this.setSidebarMenuVisible(false)
    },
    logoutButtonClicked() {
      this.setUserData(undefined);
      this.$router.push('/login');
    },
    toggleNetworkProductDropdown() {
      if (this.productConfig.network === 'Vodacom') {
        this.openDropdown = 0;
      }
      if (this.productConfig.network === 'MTN') {
        this.openDropdown = 1;
      }
      if (this.productConfig.currencySymbol === '$' && this.productConfig.network !== 'AIS' ) {
        this.openDropdown = 2;
      }
      if (this.productConfig.network === 'Penrose') {
        this.openDropdown = 3;
      }
      if (this.productConfig.network === 'AIS') {
        this.openDropdown = 5;
      }
    },
  },
  beforeMount() {
    this.toggleNetworkProductDropdown();
  }
}
</script>

<style scoped>
.sidebar-container {
  position: relative;
  height: 100vh;
  background-color: #E6E6E6;
  border-radius: 16px;
}

.penrose-logo {
  border-bottom: 3px solid #000000;
}

.cell-provider-container {
  border-radius: 8px;
  background-color: #FFFFFF;
  height: 35px;
}

.cell-provider-name-text {
  padding-left: 30px;
}

.dropdown-box {
  border-radius: 8px;
  color: #FFFFFF;
  background-color: #EE5C36;
  margin-left: 10px;
}

.product-logo {
  margin-left: 15px;
  margin-right: 15px;
  width: 25px;
}

.product-text, .cell-provider-name-text {
  font-weight: 700;
}

.game-container-selected {
  background-color: #EE5C36;
  color: #FFFFFF;
  padding: 4px;
  border: 0;
  border-radius: 16px;
}

.game-container-default {
  padding: 4px;
  border: 1px solid #c5c5c5;
  border-radius: 16px;
}

.close-button, .logout-button {
  position: relative;
  font-size: 20px;
  font-weight: 600;
  height: 35px;
  border: 0;
  border-radius: 16px;
  color: #FFFFFF;
  background-color: #EE5C36;
}

.game-container-default :hover {
  cursor: pointer;
  opacity: 0.9;
  color: #EE5C36;
}

.slide-enter-from {
  transform: scaleY(0);
}

.slide-leave-to {
  transform: scaleY(0);
}

.drop-down-menu, .drop-down-menu-vc {
  transform-origin: top;
  transition: transform .4s ease-in-out;
}

.product-container {
  max-height: 290px;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-color: #EE5C36 transparent;
  scrollbar-width: thin;
}
</style>
