<template>
  <div class="overlay">
    <div class="main-box">
      <h5 class="text-center"><b>Please ensure your changes are correct before confirming.</b></h5>
      <i class="text-center">You have made changes to the following campaigns:</i>
      <ul>
        <li v-for="campaign in editedCampaigns">
          ID: {{campaign.CampaignId}} | Postback = {{campaign.PostbackPercentage}}% | Nominal CPA: {{campaign.NominalCpa}} | {{campaign.Network}}
        </li>
      </ul>
      <div class="d-flex justify-content-center align-items-center">
        <div @click="confirmClicked()" class="main-button green">Confirm Changes</div>
        <div @click="oncCancelClicked()" class="main-button">Cancel</div>
      </div>
    </div>
  </div>
</template>

<script>
import CampaignHubService from "@/services/campaignHubService";
import {mapMutations, mapState} from "vuex";

export default {
  name: "SaveChanges",
  computed: {
    ...mapState(['selectedCampaigns', 'campaignChannelTable', 'editedCampaigns', 'userData'])
  },
  props: {
    isChannel: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    ...mapMutations(['pushEditedCampaign', 'setEditedCampaigns', 'setShowConfirmEdit']),
    async confirmClicked() {
      if (!this.isChannel) {
        await this.editedCampaigns.asyncForEach(async (campaign) => {
          await CampaignHubService.editCampaign(campaign.CampaignId, campaign.PostbackPercentage, this.userData.LoginName, campaign.NominalCpa)
        })
      } else {
        await this.editedCampaigns.asyncForEach(async (campaign) => {
          await CampaignHubService.editChannel(campaign.CampaignId, campaign.PostbackPercentage, this.userData.LoginName)})
      }
      this.campaignChannelTable.forEach((campaign) => campaign.isSelected = false)
      this.setShowConfirmEdit(false)
    },
    oncCancelClicked() {
      this.$emit('cancel-clicked', false); // Emit an event to the parent with a boolean value
      this.setEditedCampaigns([])
      this.setShowConfirmEdit(false)
    }
  },
  beforeMount() {
  }
}
</script>

<style scoped>
.main-button {
  width: 200px;
  height: 40px;
  border: solid gray 1px;
  display: flex;justify-content: center;
  align-items: center;
  font-size: 12px;
  margin: 10px;
}

.green {
  background: green;
  color: white;
}
body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  background-color: rgba(255, 255, 255, 0.4); /* Semi-transparent background */
  backdrop-filter: blur(2px); /* Apply blur to the background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above other content */
}

.main-box {
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 500px;
  height: 300px;
  text-align: center;
  background: white;
}

.main-box h2 {
  font-size: 18px;
  margin-bottom: 10px;
}

.main-box p {
  margin-bottom: 20px;
}

.main-box ul {
  list-style: none;
  padding: 0;
  margin-bottom: 20px;
}

.main-box li {
  margin-bottom: 5px;
}

.modal-actions {
  display: flex;
  justify-content: space-around;
}

.confirm-button,
.cancel-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.confirm-button {
  background-color: #4caf50;
  color: white;
}

.cancel-button {
  background-color: #ccc;
  color: black;
}
</style>
