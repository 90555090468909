<template>
  <div class="d-flex flex-column justify-content-center col-12 mt-3">
    <div class="d-flex flex-row align-items-center col-12">
      <div class="d-flex flex-row justify-content-center col-4">
        <button @click="calendarModeClicked('Daily')"
                :class="`${this.monthly === 0  ? 'toggle-date-button-active' : 'toggle-date-button-inactive'}`">Daily
        </button>
        <button @click="calendarModeClicked('Monthly')"
                :class="`${this.monthly === 1  ? 'toggle-date-button-active' : 'toggle-date-button-inactive'}`">Monthly
        </button>
      </div>
      <div class="d-flex flex-row justify-content-around flex-wrap col-4">
        <input v-model="dateRangePicked" class="col-12 col-md-4 col-sm-5 col-xl-5 date-picker"
               placeholder="Select Date..."
               type="text"
               id="datepicker" autocomplete="off"/>
        <div @click="campaignDropdownClicked()" v-if="userData.Internal && productConfig.campaignIdTitle"
             class="d-flex flex-column col-12 col-md-4 col-sm-5 col-xl-5 justify-content-center campaign-container">
          <img class="dropdown-img" src="@/assets/dropdown-icon.png">
          <div v-if="loadingCampaignData" class="spinner-grow spinner" role="status" style="color: #EE5C36">
          </div>
          <div class="d-flex flex-row align-items-center campaign-header p-1 col-12">{{
              this.truncate(campaignTitle, 26)
            }}
          </div>
          <div v-if="campaignDropdownVisible" class="dropdown-container col-12">
            <div @click="campaignSelected(null, 'All Campaigns')" v-if="campaignTitle !== 'All Campaigns'"
                 class="d-flex flex-column justify-content-center dropdown-row">All Campaigns
            </div>
            <div @click="campaignSelected(campaign.CampaignId, campaign.CustomDescription)"
                 v-for="campaign in this.campaignList" :key="index"
                 class="d-flex flex-column justify-content-center dropdown-row">
              {{ this.truncate(campaign.CampaignId + ': ' + campaign.CustomDescription, 30) }}
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex flex-row justify-content-center col-4">
        <button @click="confirmDateRangePicked(dateRangePicked)"
                class="col-3 col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 m-1 confirm-button">Confirm
        </button>
      </div>
    </div>
    <div v-if="productConfig.productId === 35" class="d-flex flex-column align-items-center mt-2">
      <div class="d-flex flex-row justify-content-center col-12 col-sm-8 col-md-6 col-lg-4 col-xl-4 radio-container">
        <div class="d-flex flex-row justify-content-center align-items-center p-1 col-2">
          <input @change="confirmDateRangePicked(dateRangePicked)" type="radio"
                 :value="undefined" v-model="serviceId"/>
          <label>All</label>
        </div>
        <div class="d-flex flex-row justify-content-center align-items-center p-1 col-2">
          <input @change="confirmDateRangePicked(dateRangePicked)" type="radio"
                 value="mzansi-rhythm-free" v-model="serviceId"/>
          <label>Free</label>
        </div>
        <div class="d-flex flex-row justify-content-center align-items-center p-1 col-2">
          <input @change="confirmDateRangePicked(dateRangePicked)" type="radio"
                 value="mzansi-rhythm-daily" v-model="serviceId"/>
          <label>Daily</label>
        </div>
        <div class="d-flex flex-row justify-content-center align-items-center p-1 col-2">
          <input @change="confirmDateRangePicked(dateRangePicked)" type="radio"
                 value="mzansi-rhythm-weekly" v-model="serviceId"/>
          <label>Weekly</label>
        </div>
        <div class="d-flex flex-row justify-content-center align-items-center p-1 col-3">
          <input @change="confirmDateRangePicked(dateRangePicked)" type="radio"
                 value="mzansi-rhythm-monthly" v-model="serviceId"/>
          <label>Monthly</label>
        </div>
      </div>
    </div>
    <div v-if="hasFreeTierProduct" class="d-flex flex-column align-items-center mt-2">
      <div class="d-flex flex-row justify-content-center col-10 col-sm-8 col-md-6 col-lg-4 col-xl-3 radio-container">
        <div class="d-flex flex-row justify-content-center align-items-center p-1 radio-button-zone">
          <input @change="confirmDateRangePicked(dateRangePicked)" type="radio"
                 :value="undefined" v-model="serviceId"/>
          <label>All</label>
        </div>
        <div v-if="productConfig.serviceId"
             class="d-flex flex-row justify-content-center align-items-center p-1 radio-button-zone">
          <input @change="confirmDateRangePicked(dateRangePicked)" type="radio"
                 :value="`${productConfig.serviceId}`" v-model="serviceId"/>
          <label>Free</label>
        </div>
        <div v-if="productConfig.pricePointServiceIds[0]"
             class="d-flex flex-row justify-content-center align-items-center p-1 radio-button-zone">
          <input @change="confirmDateRangePicked(dateRangePicked)" type="radio"
                 :value="`${productConfig.pricePointServiceIds[0]}`" v-model="serviceId"/>
          <label>Daily</label>
        </div>
        <div v-if="productConfig.pricePointServiceIds[1]"
             class="d-flex flex-row justify-content-center align-items-center p-1 radio-button-zone">
          <input @change="confirmDateRangePicked(dateRangePicked)" type="radio"
                 :value="`${productConfig.pricePointServiceIds[1]}`" v-model="serviceId"/>
          <label>Weekly</label>
        </div>
        <div v-if="productConfig.pricePointServiceIds[2]"
             class="d-flex flex-row justify-content-center align-items-center p-1 radio-button-zone">
          <input @change="confirmDateRangePicked(dateRangePicked)" type="radio"
                 :value="`${productConfig.pricePointServiceIds[2]}`" v-model="serviceId"/>
          <label>Monthly</label>
        </div>
      </div>
    </div>
    <div v-if="showDailyWeeklyPricePoint && productConfig.endpoint !== 'chess-masters'"
         class="d-flex flex-column align-items-center mt-2">
      <div class="d-flex flex-row justify-content-center col-10 col-sm-8 col-md-6 col-lg-4 col-xl-3">
        <div class="d-flex flex-row justify-content-center align-items-center p-1 col-3">
          <input @change="confirmDateRangePicked(dateRangePicked)" type="radio" :value="undefined" v-model="serviceId"/>
          <label>All</label>
        </div>
        <div class="d-flex flex-row justify-content-center align-items-center p-1 col-3">
          <input @change="confirmDateRangePicked(dateRangePicked)" type="radio"
                 :value="`${productConfig.pricePointServiceIds[0]}`" v-model="serviceId"/>
          <label>Daily</label>
        </div>
        <div class="d-flex flex-row justify-content-center align-items-center p-1 col-3">
          <input @change="confirmDateRangePicked(dateRangePicked)" type="radio"
                 :value="`${productConfig.pricePointServiceIds[1]}`" v-model="serviceId"/>
          <label>Weekly</label>
        </div>
        <div v-if="productConfig.pricePointServiceIds[2]"
             class="d-flex flex-row justify-content-center align-items-center p-1 col-3">
          <input @change="confirmDateRangePicked(dateRangePicked)" type="radio"
                 :value="`${productConfig.pricePointServiceIds[2]}`" v-model="serviceId"/>
          <label>Monthly</label>
        </div>
      </div>
    </div>
    <div v-if="productConfig.endpoint === 'chess-masters'" class="d-flex flex-column align-items-center mt-2">
      <div class="d-flex flex-row justify-content-center col-10 col-sm-8 col-md-6 col-lg-4 col-xl-3">
        <label for="serviceSelect">Select Service: </label>
        <div class="p-1"></div>
        <select id="serviceSelect" v-model="serviceId" @change="confirmDateRangePicked(dateRangePicked)">
          <option :value="undefined">All</option>
          <option :value="`${productConfig.pricePointServiceIds[0]}`">Daily (Old)</option>
          <option :value="`${productConfig.pricePointServiceIds[1]}`">Weekly (Old)</option>
          <option v-if="productConfig.pricePointServiceIds[2]" :value="`${productConfig.pricePointServiceIds[2]}`">
            Monthly (Old)
          </option>
          <option v-if="productConfig.pricePointServiceIds[3]" :value="`${productConfig.pricePointServiceIds[3]}`">
            Daily (Fusion)
          </option>
          <option v-if="productConfig.pricePointServiceIds[4]" :value="`${productConfig.pricePointServiceIds[4]}`">
            Weekly (Fusion)
          </option>
          <option v-if="productConfig.pricePointServiceIds[5]" :value="`${productConfig.pricePointServiceIds[5]}`">
            Monthly (Fusion)
          </option>
          <option v-if="productConfig.pricePointServiceIds[6]" :value="`${productConfig.pricePointServiceIds[6]}`">
            Daily (Apigee)
          </option>
          <option v-if="productConfig.pricePointServiceIds[7]" :value="`${productConfig.pricePointServiceIds[7]}`">
            Weekly (Apigee)
          </option>
          <option v-if="productConfig.pricePointServiceIds[8]" :value="`${productConfig.pricePointServiceIds[8]}`">
            Monthly (Apigee)
          </option>
        </select>
      </div>
    </div>
    <div class="d-flex flex-row justify-content-between container-fluid mt-2">
      <div class="d-flex flex-column revenue-metric-container p-1 col-3 col-xs-3 col-sm-3 col-md-2 col-lg-3 col-xl-2">
        <div class="metric-container-header p-1">Subscription Count</div>
        <div class="metric-value-text p-1">
          {{ new Intl.NumberFormat('en-US').format(subscriptionMetricsData.AllSubscriptions) }}
        </div>
        <div class="d-flex flex-row text-center align-items-center pb-1">
          <div class="col-2"><img class="col-6"
                                  :src="Helpers.findArrowImageModule(Helpers.deltaPercentage(subscriptionMetricsData.AllSubscriptions , subscriptionMetricsData.AllSubscriptionsHistoricDelta))">
          </div>
          <div
              :class="`${Helpers.setTextColour(Helpers.deltaPercentage(subscriptionMetricsData.AllSubscriptions , subscriptionMetricsData.AllSubscriptionsHistoricDelta)) ? 'green-text' : 'red-text' }`">
            {{
              Helpers.deltaPercentage(subscriptionMetricsData.AllSubscriptions, subscriptionMetricsData.AllSubscriptionsHistoricDelta)
            }}
          </div>
        </div>
      </div>
      <div v-if="!productConfig.endpoint.includes('fantasy')"
           class="d-flex flex-column revenue-metric-container p-1 col-3 col-xs-3 col-sm-3 col-md-2 col-lg-3 col-xl-2">
        <div class="metric-container-header p-1">Freemium Conversions</div>
        <div class="metric-value-text p-1">{{ formatNumber(subscriptionMetricsData.FreemiumConversions) }}</div>
        <div class="d-flex flex-row text-center align-items-center">
        </div>
      </div>
      <div v-if="!productConfig.endpoint.includes('fantasy')"
           class="d-flex flex-column revenue-metric-container p-1 col-3 col-xs-3 col-sm-3 col-md-2 col-lg-3 col-xl-2">
        <div class="metric-container-header p-1">Marketing Investment</div>
        <div class="metric-value-text p-1">{{
            formatMarketingInvestment(subscriptionMetricsData.MarketingInvestment)
          }}
        </div>
        <div class="d-flex flex-row text-center align-items-center">
        </div>
      </div>
      <div class="d-flex flex-column revenue-metric-container p-1 col-3 col-xs-3 col-sm-3 col-md-2 col-lg-3 col-xl-2">
        <div class="metric-container-header p-1">Closing Subscriber Base</div>
        <div class="metric-value-text p-1">{{ formatNumber(subscriptionMetricsData.TotalPlayers) }}</div>
        <div class="d-flex flex-row text-center align-items-center pb-1">
          <div class="col-2"><img class="col-6"
                                  :src="Helpers.findArrowImageModule(Helpers.deltaPercentage(subscriptionMetricsData.TotalPlayers , subscriptionMetricsData.TotalPlayersHistoricDelta))">
          </div>
          <div
              :class="`${Helpers.setTextColour(Helpers.deltaPercentage(subscriptionMetricsData.TotalPlayers , subscriptionMetricsData.TotalPlayersHistoricDelta)) ? 'green-text' : 'red-text' }`">
            {{
              Helpers.deltaPercentage(subscriptionMetricsData.TotalPlayers, subscriptionMetricsData.TotalPlayersHistoricDelta)
            }}
          </div>
        </div>
      </div>

    </div>
    <div class="d-flex flex-column align-items-center col-12 mt-2 mb-4">
      <LineChart :subscriptionMetric="subscriptionMetric.DailySubscriptions"></LineChart>
    </div>
    <div v-if="productConfig.network !== 'MTN' && campaignId === undefined"
         class="d-flex flex-column align-items-center mt-2 mb-2">
      <div class="col-11" style="overflow-x:auto;">
        <table class="table table-hover" id="tblToExcl">
          <thead>
          <tr>
            <th class="" scope="col">Date</th>
            <th class="">Opening Base</th>
            <th class="">Closing Base</th>
            <th class="">Subscribed</th>
            <th class="">Organic Web</th>
            <th class="">Organic Ussd</th>
            <th class="">SIM Push</th>
            <th class="">Unsubscribed</th>
            <th class="">Global Unsubs</th>
            <th class="">External Unsubs</th>
            <th class="">Daily Net</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(day, index) in dailySubscriptionTable.slice().reverse()" :key=index>
            <td> {{ Helpers.dateConversion(day.Date.slice(0, 10,), monthly) }}</td>
            <td>{{ formatNumber(day.OpeningBase) }}</td>
            <td>{{ formatNumber(day.ClosingBase) }}</td>
            <td>{{ formatNumber(day.TotalSubscribedUsers) }}</td>
            <td>{{ formatNumber(day.OrganicWeb) }}</td>
            <td>{{ formatNumber(day.OrganicUSSD) }}</td>
            <td>{{ formatNumber(day.Simpush) }}</td>
            <td>{{ formatNumber(day.TotalCancelledUsers) }}</td>
            <td>{{ formatNumber(day.GlobalUnsubs) }}</td>
            <td>{{ formatNumber(day.ExternalUnsubs) }}</td>
            <td>{{ formatNumber(day.DailyNet) }}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <button class="d-flex flex-column justify-content-center export-button p-2" @click="htmlTableToExcel()">Export to
        Excel
      </button>
    </div>
    <div v-if="productConfig.network !== 'MTN' && campaignId" class="d-flex flex-column align-items-center mt-2 mb-2">
      <div class="col-11" style="overflow-x:auto;">
        <table class="table table-hover" id="tblToExcl">
          <thead>
          <tr>
            <th class="" scope="col">Date</th>
            <th class="">Subscribed</th>
            <th class="">Unsubscribed</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(day, index) in dailySubscriptionTable.slice().reverse()" :key=index>
            <td> {{ Helpers.dateConversion(day.Date.slice(0, 10)) }}</td>
            <td>{{ new Intl.NumberFormat('en-US').format(day.TotalSubscribedUsers) }}</td>
            <td>{{ new Intl.NumberFormat('en-US').format(day.TotalCancelledUsers) }}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <button class="d-flex flex-column justify-content-center export-button p-2" @click="htmlTableToExcel()">Export to
        Excel
      </button>
    </div>
    <div v-if="productConfig.network === 'MTN'" class="d-flex flex-column align-items-center mt-2 mb-2">
      <div class="col-11" style="overflow-x:auto;">
        <table class="table table-hover" id="tblToExcl">
          <thead>
          <tr>
            <th class="" scope="col">Date</th>
            <th class="">Opening Base</th>
            <th class="">Closing Base</th>
            <th class="">Subscribed</th>
            <th class="">Active</th>
            <th class="">Grace</th>
            <th class="">Interested</th>
            <th class="">Pending</th>
            <th class="">Suspended</th>
            <th class="">Unsubscribed</th>
            <th class="">Daily Net</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(day, index) in dailySubscriptionTable.slice().reverse()" :key=index>
            <td>{{ Helpers.dateConversion(day.Date.slice(0, 10), monthly) }}</td>
            <td>{{ formatNumber(day.OpeningBase) }}</td>
            <td>{{ formatNumber(day.ClosingBase) }}</td>
            <td>{{ formatNumber(day.TotalSubscribedUsers) }}</td>
            <td>{{ formatNumber(day.Active) }}</td>
            <td>{{ formatNumber(day.Grace) }}</td>
            <td>{{ formatNumber(day.Interested) }}</td>
            <td>{{ formatNumber(day.Pending) }}</td>
            <td>{{ formatNumber(day.Suspended) }}</td>
            <td>{{ formatNumber(day.TotalCancelledUsers) }}</td>
            <td>{{ formatNumber(day.DailyNet) }}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <button class="d-flex flex-column justify-content-center export-button p-2" @click="htmlTableToExcel()">Export to
        Excel
      </button>
    </div>
  </div>
</template>

<script>
import LineChart from "@/views/components/charts/LineChart";
import flatpickr from "flatpickr";
import moment from "moment";
import DashboardService from "@/services/dashboardService";
import {mapMutations, mapState} from "vuex";
import * as XLSX from 'xlsx/xlsx.mjs';
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";

export default {
  name: "ProductSubscription",
  inheritAttrs: false,
  props: ['subscriptionMetric'],
  components: {
    LineChart
  },
  computed: {
    ...mapState(['productConfig', 'startingDateCalendar', 'endingDateCalendar', 'userData']),
    hasFreeTierProduct() {
      const specialProductIds = [26, 27, 28, 18, 21, 38];
      return specialProductIds.includes(this.productConfig.productId);
    },
    showDailyWeeklyPricePoint() {
      const excludedProductIds = [18, 35, 28, 27, 26, 21];
      return this.productConfig.weeklyPricePoint &&
          !excludedProductIds.includes(this.productConfig.productId);
    }
  },
  data() {
    return {
      dateRangePicked: `${moment().subtract(7, 'd').format('YYYY-MM-DD')} to ${moment().subtract(1, 'd').format('YYYY-MM-DD')}`,
      subscriptionMetricsData: [],
      startingDatePicked: undefined,
      endDatePicked: undefined,
      dailySubscriptionTable: [],
      serviceId: null,
      campaignList: [],
      campaignId: undefined,
      campaignDropdownVisible: false,
      campaignTitle: `All Campaigns`,
      loadingCampaignData: false,
      calendarButtonText: undefined,
      dailyCalendarMode: {
        mode: "range",
        locale: {
          firstDayOfWeek: 1
        }
      },
      monthlyCalendarMode: {
        mode: "range",
        plugins: [
          new monthSelectPlugin({
            shorthand: false,
            dateFormat: "m.y",
          })
        ]
      },
      monthly: 0,
    }
  },
  methods: {
    ...mapMutations(['setTriggerNewChart', 'setLineChartData', 'setLineChartToLoad', 'setStartingDateCalendar', 'setEndingDateCalendar']),
    truncate(text, length) {
      if (text.length > length) {
        return text.substring(0, length) + '...';
      }
      return text;
    },
    formatNumber(number) {
      if (Number.isNaN(number) || number === null || number === undefined) {
        return 'N/A';
      }
      return new Intl.NumberFormat('en-US').format(number);
    },
    formatMarketingInvestment(number) {
      if (Number.isNaN(number) || number === null || number === undefined) {
        return 'N/A';
      }
      return `${this.productConfig.currencySymbol + new Intl.NumberFormat('en-US').format(number)}`;
    },
    htmlTableToExcel() {
      let table_elt = document.getElementById("tblToExcl");
      let workbook = XLSX.utils.table_to_book(table_elt);
      XLSX.writeFile(workbook, `${this.productConfig.productTitle} Subscription Report.xlsb`);
    },
    async confirmDateRangePicked(dateRange) {
      this.sliceDateRange(dateRange);
      await this.setNewSubscriptionMetrics();
      this.setTriggerNewChart(true);
      this.setLineChartData(this.updatedSubscriptionMetricsData.DailySubscriptions);
    },
    sliceDateRange(dateRange) {
      if (this.monthly === 0) {
        this.startingDatePicked = dateRange.slice(0, 10);
        this.endDatePicked = dateRange.substr(-10);
        this.setStartingDateCalendar(this.startingDatePicked);
        this.setEndingDateCalendar(this.endDatePicked);
      }
      if (this.monthly === 1) {
        this.convertDates(dateRange.slice(0, 5), dateRange.substr(-5));
        this.setStartingDateCalendar(this.startingDatePicked);
        this.setEndingDateCalendar(this.endDatePicked);
      }
    },
    convertDates(startingDate, endDate) {
      const [startMonth, startYear] = startingDate.split('.').map(part => parseInt(part, 10));
      const [endMonth, endYear] = endDate.split('.').map(part => parseInt(part, 10));
      this.startingDatePicked = `20${startYear.toString().padStart(2, '0')}-${startMonth.toString().padStart(2, '0')}-01`;
      const lastDay = new Date(20 + endYear, endMonth, 0).getDate();
      this.endDatePicked = `20${endYear.toString().padStart(2, '0')}-${endMonth.toString().padStart(2, '0')}-${lastDay}`;
    },
    setDefaultSubscriptionMetrics() {
      this.subscriptionMetricsData = this.subscriptionMetric;
      this.dailySubscriptionTable = this.subscriptionMetric.DailySubscriptions;
      this.serviceId = undefined;
    },
    triggerUpdatedChart() {
      this.setTriggerNewChart(true);
      this.setLineChartData(this.updatedSubscriptionMetricsData.DailySubscriptions);
    },
    setUpdatedDateRange() {
      this.dateRangePicked = `${this.startingDateCalendar} to ${this.endingDateCalendar}`;
      this.startingDatePicked = this.startingDateCalendar;
      this.endDatePicked = this.endingDateCalendar;
    },
    async setNewSubscriptionMetrics() {
      this.updatedSubscriptionMetricsData = (await DashboardService.getSubscriptionStats(`${this.startingDatePicked}`, `${this.endDatePicked}`, this.productConfig.productId, this.serviceId, this.campaignId, this.monthly)).data;
      this.dailySubscriptionTable = this.updatedSubscriptionMetricsData.DailySubscriptions;
      this.subscriptionMetricsData = this.updatedSubscriptionMetricsData;
    },
    async checkDateRangeChanged() {
      if (this.startingDateCalendar !== undefined) {
        this.serviceId = undefined;
        this.setUpdatedDateRange();
        await this.setNewSubscriptionMetrics();
        this.triggerUpdatedChart();
      } else {
        this.setDefaultSubscriptionMetrics();
      }
    },
    campaignDropdownClicked() {
      this.campaignDropdownVisible = !this.campaignDropdownVisible;
    },
    async getCampaignsList(product, network) {
      this.campaignList = (await DashboardService.getCampaignsByProduct(product, network)).data[0];
    },
    async campaignSelected(campaignId, description) {
      this.loadingCampaignData = true;
      this.campaignId = campaignId;
      if (campaignId !== null && description !== 'All Campaigns') {
        this.campaignTitle = `${campaignId}: ${description}`;
      } else {
        this.campaignTitle = description;
      }
      await this.confirmDateRangePicked(this.dateRangePicked);
      this.loadingCampaignData = false;
    },
    calendarModeClicked(buttonText) {
      if (buttonText === 'Monthly') {
        this.monthly = 1;
        flatpickr('#datepicker', this.monthlyCalendarMode);
      } else if (buttonText === 'Daily') {
        this.monthly = 0;
        flatpickr('#datepicker', this.dailyCalendarMode);
      }
    },
  },
  mounted() {
    flatpickr('#datepicker', this.dailyCalendarMode);
  },
  async beforeMount() {
    await this.checkDateRangeChanged();
    await this.getCampaignsList(this.productConfig.campaignIdTitle, this.productConfig.network);
    console.log(this.productConfig.network)
    console.log(this.campaignId)
  }
}
</script>

<style scoped>
.confirm-button, .export-button {
  height: 35px;
  border-radius: 18px;
  font-weight: 600;
  font-size: 20px;
  border: 0;
  background-color: #EE5C36;
  color: #FFFFFF;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.toggle-date-button-active, .toggle-date-button-inactive {
  width: 100px;
  height: 35px;
  border-radius: 18px;
  font-weight: 600;
  font-size: 20px;
  border: 0;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.toggle-date-button-active {
  background-color: #EE5C36;
  color: #FFFFFF;
}

.toggle-date-button-inactive {
  background-color: transparent;
  color: #000000;
}

.revenue-metric-container {
  border-radius: 12px;
  font-weight: 600;
  border: 0;
  background-color: #F4D1CE;
  color: #000000;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.metric-container-header, .red-text, .green-text {
  font-size: 18px;
}

.metric-value-text {
  font-size: 30px
}

.red-text {
  color: #ff0000;
}

.green-text {
  color: #207520;
}

input {
  text-align: center;
}

.date-picker, .campaign-container {
  height: 40px;
}

.campaign-container {
  position: relative;
}

.spinner {
  position: absolute;
  right: -38px;
}

.dropdown-img {
  position: absolute;
  right: 8px;
  top: 15px;
  width: 16px;
}

.dropdown-container {
  position: absolute;
  z-index: 1;
  background-color: #fffdfd;
  top: 40px;
  height: 240px;
}

.dropdown-row {
  height: 40px;
  border: .8px solid black;
  padding-left: 4px;
  font-size: 12.5px;
}

.campaign-header {
  border: .8px solid black;
  height: 100%;
}

@media only screen and (max-width: 576px) {
  .metric-container-header, .red-text, .green-text {
    font-size: 12px;
  }

  .toggle-date-button-active, .toggle-date-button-inactive {
    width: 55px;
    font-size: 10px;
    height: 28px;
  }

  .metric-value-text {
    font-size: 12px;
  }

  .export-button {
    height: 28px;
    font-size: 15px;
  }

  table {
    margin-top: 20px;
    font-size: 8.5px;
  }

  .confirm-button {
    height: 28px;
    font-size: 18px;
  }

  .revenue-metric-container {
    width: 24% !important;
  }

  input {
    font-size: 10.5px;
  }
}

.radio-button-zone {
  width: 30%;
}

@media only screen and (max-width: 380px) {
  .metric-container-header, .red-text, .green-text {
    font-size: 10.5px;
  }

  .flatpickr-input, .campaign-header {
    height: 30px;
  }

  .overview-button, .confirm-button {
    width: 50px;
    font-size: 10px;
  }

  input {
    font-size: 10px;
  }

  .campaign-header, .dropdown-row {
    font-size: 10.5px;
  }
}

</style>

