<template>
  <div class="dropdown-container">
    <div class="dropdown">
      <div @click="onOptionClicked(option)" class="d-flex align-items-center drop-box p-2" v-for="option in dropDownOptions" v-bind:class="getBoxClass(option)">{{option.name}}</div>
    </div>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";

export default {
  data() {
    return {
      showDropdown: false,
      selectedOption: '',
    };
  },
  props: {    dropDownOptions: {
      type: Array,
      required: true,
    },

    header: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState(['isLoading', 'channelName', 'country'])
  },
  methods: {
    ...mapMutations(['setIsLoading', 'setChannelName', 'setCountry', 'setProductId', 'setNetwork', 'setPlatform', 'setCostCalculationtype', 'setCurrency']),
    getBoxClass(option) {
      if (this.selectedOption) {
        if (this.selectedOption === option) return 'orange-bg'
      }
    },
    onOptionClicked(option) {
      this.selectedOption = option
      if (this.header === "Channel") this.setChannelName(option)
      // if (this.header === "Country") this.setCountry(option)
      if (this.header === "ProductId") {
        this.setProductId(option)
        this.setCountry(option.country)
        this.setNetwork(option.network)
        console.log(this.country)
      }
      // if (this.header === "Network") this.setNetwork(option)
      if (this.header === "Platform") this.setPlatform(option)
      if (this.header === "CostCalculation") this.setCostCalculationtype(option)
      if (this.header === "Currency") this.setCurrency(option)
      this.$emit('update-dropdown', false); // Emit an event to the parent with a boolean value
    },
  },
};
</script>

<style scoped>
.orange-bg {
  background-color: #EE5C36 !important;
  color: white !important;
}
.drop-box {
  width: 100%;
  height: 42px;
  border: solid gray 1px;
  border-top: none !important;
  z-index: 210;
  background-color: white;
}
.dropdown-container {
  width: 100%;
  max-height: 200px;
  min-height: 200px;
  overflow-y: scroll;
  position: relative;
}

.dropdown input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 50px;
  z-index: 1001
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  z-index: 10;
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdown-menu li {
  padding: 8px;
  cursor: pointer;
}

.dropdown-menu li:hover {
  background-color: #f0f0f0;
}
</style>
